import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";

export function Loading() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        bgcolor: "rgba(255, 255, 255, 0.2)",
        position: "absolute",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          display: "block",
          bgcolor: "#fff",
          px: 2,
          py: 1,
          border: "1px solid #000",
        }}
      >
        Loading...
      </Typography>
    </Box>
  );
}
