import "./index.css";
import React, { useEffect, useRef } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { Api, type IApi } from "./services/worker";
import { ApiContext } from "./context";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Loading } from "./components/Loading";
import ReactDOM from "react-dom/client";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import type { ExternalState } from "./types";
// @ts-ignore
import wasmDataCompressed from "url:@goodtools/wiregasm/dist/wiregasm.data.gz";
// @ts-ignore
import wasmModuleCompressed from "url:@goodtools/wiregasm/dist/wiregasm.wasm.gz";

const AppLazy = React.lazy(() => import("./App"));
const FileUploadViewLazy = React.lazy(() => import("./components/FileUploadView"));
const FileLoadViewLazy = React.lazy(() => import("./components/FileLoadView"));

enum Status {
  Initializing = "Initializing",
  Initialized = "Initialized",
  Processing = "Processing file",
  Completed = "Completed",
}

const FILE_URL_SESSION_KEY = "WSFileUrl";

class UrlStorage {
  #key: string;
  #storage: Storage;

  constructor(key: string) {
    this.#key = key;
    this.#storage = sessionStorage;
  }

  get() {
    return this.#storage.getItem(this.#key);
  }
  set(value: string) {
    this.#storage.setItem(this.#key, value);
  }
}

const storage = new UrlStorage(FILE_URL_SESSION_KEY);

function getFileUrl() {
  const fromQuery = new URLSearchParams(window.location.search).get("f");
  if (fromQuery) return fromQuery;
  const fromSession = storage.get();
  if (fromSession) return fromSession;
  return null;
}

function AppInit() {
  const fileUrl = useRef(getFileUrl());
  const initialStateRef = useRef<ExternalState>();
  const [updatedAt, setUpdatedAt] = React.useState(Date.now());
  const [filename, setFilename] = React.useState<string>("noname.pcap");
  const [arrayBuffer, setArrayBuffer] = React.useState<ArrayBuffer>();
  const [proceed, setProceed] = React.useState(false);
  const [status, setStatus] = React.useState<string>();
  const [workerApi, setApi] = React.useState<IApi>();

  useEffect(() => {
    if (fileUrl.current) {
      // Store the file URL in the Session Storage, so that the user can refresh the page without losing the file.
      storage.set(fileUrl.current);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [fileUrl.current]);

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.source === null) return;
      if (event.data.type == "set-initial-state") {
        initialStateRef.current = event.data.payload;
        setUpdatedAt(Date.now());
      }
    };
    window.parent.postMessage({ type: "ready" }, "*");
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    if (!proceed) return;
    setStatus(Status.Initializing);
    const obj = new Worker(new URL("blob-url:./worker.ts", import.meta.url), {
      type: "module",
    });
    obj.postMessage({
      type: "init",
      wasmDataUrl: wasmDataCompressed,
      wasmModuleUrl: wasmModuleCompressed,
    });
    obj.onmessage = async event => {
      if (event.data.type === "init") {
        setApi(new Api(obj));
        setStatus(Status.Initialized);
      }
    };
    return () => {
      if (obj) {
        obj.terminate();
      }
    };
  }, [proceed]);

  useEffect(() => {
    if (Status.Completed === status) return;
    if (!arrayBuffer || !workerApi) return;
    (async () => {
      try {
        setStatus(Status.Processing);
        await workerApi.loadFromBuffer(arrayBuffer, filename);
      } catch (e) {
        console.error(e);
        return;
      }
      setStatus(Status.Completed);
    })();
  }, [arrayBuffer, workerApi, status]);

  if (status === Status.Completed && workerApi) {
    return (
      <ApiContext.Provider value={workerApi}>
        <React.Suspense fallback={<Loading />}>
          <AppLazy key={updatedAt} initialState={initialStateRef.current} />
        </React.Suspense>
      </ApiContext.Provider>
    );
  }

  const Component = fileUrl.current ? FileLoadViewLazy : FileUploadViewLazy;
  return (
    <Dialog
      open
      PaperProps={{
        variant: "outlined",
        elevation: 0,
        sx: {
          border: "none",
        },
      }}
    >
      <DialogContent>
        <Typography>
          The application size is ~18MB. <br />
          Loading may take a while.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          gap: 0.5,
        }}
      >
        {!proceed ? (
          <Button variant="contained" onClick={() => setProceed(true)} fullWidth autoFocus>
            Proceed
          </Button>
        ) : (
          <Box>
            <Box sx={{ pb: 1 }}>
              {status === Status.Initialized && (
                <React.Suspense fallback={null}>
                  <Component
                    url={fileUrl.current as string}
                    setStatus={setStatus}
                    onComplete={(file: ArrayBuffer, filename: string) => {
                      setFilename(filename);
                      setArrayBuffer(file);
                    }}
                    onError={e => {
                      alert(e);
                    }}
                  />
                </React.Suspense>
              )}
            </Box>
            <Typography variant="caption" component="div" sx={{ textAlign: "center" }}>
              {status}...
            </Typography>
          </Box>
        )}
      </DialogActions>
      {proceed && status !== Status.Initialized && (
        <Box>
          <LinearProgress sx={{ height: 10 }} />
        </Box>
      )}
    </Dialog>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <CssBaseline />
    <AppInit />
  </React.StrictMode>
);
